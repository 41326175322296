'use client'

import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '@atoms/index'
import Loader from '@components/loader'
import RegisterLandingTwo from '@components/register-landing-two'
import { usePathname } from 'next/navigation'

const UserProvider = ({ children }) => {
    const [userData, setUserData] = useRecoilState<any>(userState)
    const [loading, setLoading] = useState<boolean>(true)

    const pathname = usePathname()
    const isGuestPage = pathname === '/login' || pathname === '/register'

    const getUserData = async () => {
        try {
            const token = localStorage.getItem('token')
            const tokenType = localStorage.getItem('tokenType')

            if (token && tokenType === 'user') {
                const response = await fetch(
                    `${process.env.API_URL}/user/data`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                if (response.status === 200) {
                    const json = await response.json()
                    setUserData(json.data)
                }
            }
        } catch (error) {
            console.error('Failed to fetch user data:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!userData && !isGuestPage) {
            getUserData()
        } else {
            setLoading(false)
        }
    }, [])

    if (isGuestPage) {
        return <>{children}</>
    }

    if (loading) {
        return <Loader />
    }

    if (!userData) {
        return <RegisterLandingTwo />
    }

    return <>{children}</>
}

export default UserProvider
